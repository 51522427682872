import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Image from 'mui-image';
import { format, parseISO } from 'date-fns';
import {
  Container,
  Typography,
  Link,
  Card,
  CardMedia,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Grid,
  Box,
  Button,
  Paper,
} from '@mui/material';
// import { POSTS } from 'queries/graphql';
import { Loading, Error, NotFound, NoContent } from 'components/status/Status';
import Head from 'components/_base/head/Head';
import PageHeading from 'components/page-heading/PageHeading.js';
import BlogPostCategories from 'components/blog-post-categories/BlogPostCategories';
import { usePosts } from 'lib/posts';

export default function Blog() {
  // const { loading, error, data } = useQuery(POSTS);
  const { data, isLoading, isError } = usePosts();
  // console.log('BLOG.js', data);

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <Error />;
  }

  const { data: posts } = data ?? {};
  console.log('posts', posts);

  const seo = {
    title: 'Blog',
    description: 'Blog',
    slug: 'blog',
  };

  if (posts === null) {
    return <NotFound />;
  }

  return (
    <React.Fragment>
      <Head props={seo} />
      <Container sx={{ py: 10 }}>
        <PageHeading props="Blog" />
        {posts.length ? (
          <Grid
            container
            alignItems="stretch"
            rowSpacing={{ xs: 1, sm: 2, md: 3 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {posts.map((post) => (
              <Grid
                key={post.id}
                item
                xs={12}
                md={6}
                sx={{
                  border: '0px solid red',
                  display: 'flex',
                }}
              >
                <Card
                  sx={{
                    display: 'flex',
                    border: '0px solid green',
                    flexDirection: { xs: 'column-reverse', md: 'row' },
                    flexGrow: 1,
                    backgroundColor: 'action.hover',
                    mb: { xs: 1, md: 3 },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column' },
                      flexGrow: 1,
                      flexBasis: '80%',
                      // border: '1px solid red',
                    }}
                  >
                    <CardHeader
                      avatar={
                        <Avatar aria-label="author">
                          {post.attributes.createdBy?.firstname.charAt(0) +
                            post.attributes.createdBy?.lastname.charAt(0)}
                        </Avatar>
                      }
                      title={
                        post.attributes.createdBy?.firstname +
                        ' ' +
                        post.attributes.createdBy?.lastname
                      }
                      subheader={format(
                        parseISO(post.attributes.createdAt),
                        'MMMM dd, yyyy'
                      )}
                      sx={{
                        '& .MuiCardHeader-subheader': {
                          fontSize: 12,
                        },
                      }}
                      className="card-header"
                    />
                    <CardContent sx={{ p: { xs: 2 } }}>
                      <Typography
                        component="h3"
                        variant="h6"
                        sx={{ mb: 2, textTransform: 'uppercase' }}
                        gutterBottom
                      >
                        {post.attributes.name}
                      </Typography>
                      {post.attributes.excerpt ||
                        (post.attributes.content && (
                          <Typography variant="body2" sx={{ mb: { xs: 3 } }}>
                            {post.attributes.excerpt
                              ? post.attributes.excerpt
                              : post.attributes.content
                                  .substring(0, 150)
                                  .replace(/[^\w\s]/gi, '') + '...'}
                          </Typography>
                        ))}
                      <CardActions sx={{ p: 0, mb: 2 }}>
                        <Button
                          to={`/blog/${post.attributes.slug}`}
                          component={RouterLink}
                          color="primary"
                          variant="contained"
                          size="large"
                        >
                          Read More
                        </Button>
                      </CardActions>
                      <BlogPostCategories
                        categories={post.attributes.category_arlans}
                        type="simple"
                      />
                    </CardContent>
                  </Box>
                  {post?.attributes?.featuredImage?.data && (
                    <CardMedia
                      sx={{
                        width: { xs: 150 },
                        p: 1,
                        // border: '1px solid blue',
                        flexBasis: '20%',
                        mx: { xs: 'auto' },
                      }}
                    >
                      <Image
                        key={post.attributes.featuredImage.data.id}
                        src={post.attributes.featuredImage.data.attributes.url}
                        alt={
                          post.attributes.featuredImage.data.attributes
                            .alternativeText
                        }
                        title={
                          post.attributes.featuredImage.data.attributes.caption
                        }
                        showLoading
                        duration={500}
                        fit="contain"
                        loading="lazy"
                        sx={{
                          borderRadius: '10px 0 10px 0',
                        }}
                        wrapperStyle={{ height: 'auto' }}
                        className="__image"
                      />
                    </CardMedia>
                  )}
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <NoContent />
        )}
      </Container>
    </React.Fragment>
  );
}
