import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Button, Menu, MenuItem, Fade, Link } from '@mui/material';
import { red, blue, lightBlue } from '@mui/material/colors';
import 'components/navigation/header-navigation-dropdown/header-navigation-dropdown.scss';

export default function PrimaryNavigationDropdown(props) {
  const { links: menuItems, label } = props.dropdownProps;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button
        component="li"
        id="dropdown-button"
        aria-controls="fade-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        color="inherit"
        sx={{
          textTransform: 'uppercase',
          fontSize: 18,
          fontWeight: 'normal',
          borderRadius: 0,
          px: 2,
          py: 2,

          lineHeight: '1.5',
          '& .MuiSvgIcon-root': {
            marginLeft: '10px',
          },
          '&:hover': {
            backgroundColor: lightBlue['900'],
          },
        }}
      >
        {label} {anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'dropdown-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {menuItems.map((menuItem) => {
          return (
            <MenuItem
              key={menuItem.id}
              onClick={handleClose}
              sx={{
                p: 0,
              }}
            >
              <Link
                to={menuItem.url}
                component={RouterLink}
                color="inherit"
                underline="none"
                aria-current="page"
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: 'normal',
                  fontSize: 18,
                  p: 1,
                  flexGrow: 1,
                  ':hover': {
                    color: 'common.white',
                    backgroundColor: lightBlue['900'],
                  },
                }}
              >
                {menuItem.label}
              </Link>
            </MenuItem>
          );
        })}
      </Menu>
    </React.Fragment>
  );
}
