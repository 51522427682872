import { gql } from '@apollo/client';

const PAGE = gql`
  query GetPage($slug: String!) {
    findSlug(modelName: "page", slug: $slug, publicationState: "live") {
      ... on PageEntityResponse {
        data {
          __typename
          id
          attributes {
            createdAt
            name
            slug
            seo {
              __typename
              id
              title
              description
              blockSearchIndexing
            }
            components {
              ... on ComponentIntroductionIntroduction {
                __typename
                id
                heading
                description
                alignContent
                isHeadingH1Tag
              }
              ... on ComponentContactFormContactForm {
                __typename
                id
                heading
                description
                isHeadingH1Tag
              }
              __typename
              ... on ComponentAsideWithImageAsideWithImage {
                __typename
                id
                subHeading
                heading
                description
                buttons {
                  __typename
                  id
                  text
                  link
                  outlined
                  isExternal
                }
                image {
                  __typename
                  data {
                    id
                    attributes {
                      __typename
                      url
                      alternativeText
                      caption
                      provider_metadata
                    }
                  }
                }
                isContentReversed
              }
            }
          }
        }
      }
    }
  }
`;

const POSTS = gql`
  query GetPosts {
    posts(sort: "publishedAt:desc") {
      ... on PostEntityResponseCollection {
        data {
          __typename
          id
          attributes {
            __typename
            createdBy {
              id
              firstname
              lastname
            }
            name
            slug
            content
            excerpt
            featuredImage {
              data {
                __typename
                id
                attributes {
                  url
                  alternativeText
                  caption
                }
              }
            }
            createdAt
            categories {
              __typename
              data {
                __typename
                id
                attributes {
                  __typename
                  name
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`;

const POST = gql`
  query GetPost($slug: String!) {
    findSlug(modelName: "post", slug: $slug, publicationState: "live") {
      ... on PostEntityResponse {
        data {
          __typename
          id
          attributes {
            createdAt
            createdBy {
              id
              firstname
              lastname
            }
            name
            slug
            content
            video {
              __typename
              id
              videoId
            }
            seo {
              __typename
              id
              title
              description
              blockSearchIndexing
            }
            categories {
              __typename
              data {
                __typename
                id
                attributes {
                  __typename
                  name
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`;

const CATEGORIES = gql`
  query GetCategories {
    categories {
      ... on CategoryEntityResponseCollection {
        data {
          __typename
          id
          attributes {
            createdAt
            name
            slug
          }
        }
      }
    }
  }
`;

const CATEGORY = gql`
  query GetCategory($slug: String!) {
    findSlug(modelName: "category", slug: $slug, publicationState: "live") {
      ... on CategoryEntityResponse {
        data {
          __typename
          id
          attributes {
            createdAt
            name
            slug
            posts {
              __typename
              data {
                __typename
                id
                attributes {
                  __typename
                  createdAt
                  name
                  slug
                  content
                }
              }
            }
          }
        }
      }
    }
  }
`;

const NAVIGATION = gql`
  query Navigation {
    navigation {
      ... on NavigationEntityResponse {
        __typename
        data {
          __typename
          id
          attributes {
            __typename
            ... on Navigation {
              __typename
              components {
                __typename
                ... on ComponentNavigationLink {
                  __typename
                  id
                  label
                  url
                  isShowInFooter
                }
                ... on ComponentNavigationNavigation {
                  __typename
                  id
                  label
                  links {
                    __typename
                    id
                    label
                    url
                    isShowInFooter
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export { PAGE, POSTS, POST, CATEGORIES, CATEGORY, NAVIGATION };
