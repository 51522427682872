import { Typography, Link } from '@mui/material';
import 'components/website-credit/website-credit.scss';

export default function WebsiteCredit() {
  const links = [
    { label: 'React', href: 'https://reactjs.org/' },
    { label: 'Strapi', href: 'https://strapi.io/' },
    { label: 'Material-UI', href: 'https://mui.com/' },
  ];
  return (
    <Typography
      align="center"
      component="p"
      variant="body1"
      sx={{
        textAlign: 'center',
        pt: 2,
        pb: 1,
        fontSize: 12,
        color: 'text.primary',
      }}
      className="website-credit"
    >
      Built with
      {links.map((link, index) => (
        <Link
          key={index}
          href={link.href}
          rel="noreferrer noopener"
          target="_blank"
          sx={{ ml: 1, color: 'primary.main', fontWeight: 'normal' }}
          className="website-credit__link"
        >
          {link.label}
        </Link>
      ))}
    </Typography>
  );
}
