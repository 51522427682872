import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Container, Typography, Divider } from '@mui/material';
import { PAGE } from 'queries/graphql';
import { Loading, Error, NotFound } from 'components/status/Status';
import Hero from 'components/hero/Hero';
import Introduction from 'components/introduction/Introduction';
import ContactForm from 'components/contact-form/ContactForm';
import AsideWithImage from 'components/aside-with-image/AsideWithImage';
import Book from 'components/book/Book';
import Head from 'components/_base/head/Head';
import TypewriterText from 'components/text/TypewriterText';
import { usePage } from 'lib/page';

export default function Page() {
  const { slug } = useParams();

  // const { loading, error, data } = useQuery(PAGE, {
  //   variables: {
  //     slug: slug || 'home',
  //   },
  // });

  const { data, isLoading, isError } = usePage(slug || 'home');

  console.log('usePage data:', data);

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <Error />;
  }
  if (data.data.attributes === null) {
    return <NotFound />;
  }

  const { attributes: page } = data.data;
  console.log('pages - Page.js - data: ', data.data);

  return (
    <React.Fragment>
      {page.seo && <Head props={page.seo} slug={page.slug} />}
      {page.block.map((component) => {
        switch (component.__component) {
          case 'text.introduction-text-arlan':
            return (
              <Introduction
                key={component.__component + '_' + component.id}
                props={component}
              />
            );
          case 'form.contact-form-arlan':
            return (
              <ContactForm
                key={component.__component + '_' + component.id}
                props={component}
              />
            );
          case 'aside.aside-with-image':
            return (
              <AsideWithImage
                key={component.__component + '_' + component.id}
                props={component}
              />
            );
          case 'text.typewriter-text':
            return (
              <TypewriterText
                key={component.__component + '_' + component.id}
                props={component}
              />
            );
          default:
            return null;
        }
      })}
      {/* {page.Hero && <Hero props={page.Hero} />} */}
      {/* <Container
        sx={{
          py: 10,
        }}
      >
        <Typography
          component="h1"
          variant="h2"
          sx={{ mb: 10, overflowWrap: 'break-word' }}
        >
          <Divider textAlign="center">{page.Title}</Divider>
        </Typography>
        <Container
          sx={{
            width: { md: '75%' },
            // overflow: 'hidden',
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            hyphens: 'auto',
            whiteSpace: 'pre-wrap',
          }}
        >
          <ReactMarkdown>{page.Content}</ReactMarkdown>
        </Container>
      </Container> */}
    </React.Fragment>
  );
}
