import React from 'react';
import PrimaryNavigation from 'components/navigation/header-navigation/HeaderNavigation';
import SubNavigation from 'components/navigation/header-sub-navigation/HeaderSubNavigation';
import 'components/_base/header/header.scss';

export default function Header() {
  return (
    <>
      <SubNavigation />
      <PrimaryNavigation />
    </>
  );
}
