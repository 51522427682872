import React from 'react';
import { Container, Grid, Typography, Link, useTheme } from '@mui/material';
import Typewriter from 'typewriter-effect';

export default function TypewriterText(props) {
  console.log('components - text - TypewriterText.js - props: ', props);

  const { props: data } = props;
  const theme = useTheme();

  return (
    <Container maxWidth="none">
      <Grid
        container
        maxWidth="lg"
        sx={{
          mx: 'auto',
          pt: { xs: 10, md: 10 },
          pb: { xs: 3, md: 1 },
          flexDirection: 'column',
          textAlign: data.alignContent,
        }}
      >
        <Typography
          component="h3"
          variant="h2"
          sx={{
            textAlign: 'center',
            em: {
              display: 'inline-block',
              px: 1,
              fontWeight: 500,
              textTransform: 'uppercase',
              fontStyle: 'normal',
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.grey[900]
                  : theme.palette.common.white,
            },
            '.Typewriter': {
              // display: 'inline-block',
              position: 'relative',
              fontSize: { xs: 30, md: 40 },
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.primary.main
                  : theme.palette.grey[900],

              '::before': {
                content: '"<"',
              },
              '::after': {
                content: '"/>"',
              },
            },
            '.Typewriter__wrapper': {
              display: 'inline-block',
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.grey[900]
                  : theme.palette.common.white,
            },
            '.Typewriter__cursor': {
              textIndent: '-9999px',
              display: 'inline-block',
              position: 'absolute',
              top: 8,
              bottom: 8,
              width: 13,
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.primary.main
                  : theme.palette.grey[900],
            },
          }}
        >
          <em>Web Developer</em>
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .callFunction(() => {
                  // console.log('String typed out!');
                })
                .pauseFor(1500)
                .deleteAll()
                .callFunction(() => {
                  // console.log('All strings were deleted');
                })
                .start();
            }}
            options={{
              strings: [
                'JavaScript',
                'Next.js',
                'Strapi.js',
                'React.js',
                'WordPress',
                'Nuxt.js',
                'TypeScript',
              ],
              autoStart: true,
              loop: true,
            }}
          />
        </Typography>
      </Grid>
    </Container>
  );
}
