import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// import { useQuery } from '@apollo/client';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
  Container,
  AppBar,
  Box,
  Typography,
  Toolbar,
  MenuItem,
  Link,
  useScrollTrigger,
  Fab,
  Zoom,
  Menu,
  MenuList,
} from '@mui/material';
import { red, blue, lightBlue } from '@mui/material/colors';
// import { NAVIGATION } from 'queries/graphql.js';
import { useNavigation } from 'lib/navigation';
import PrimaryNavigationDropdown from 'components/navigation/header-navigation-dropdown/HeaderNavigationDropdown';
import PrimaryNavigationDrawer from 'components/navigation/header-navigation-drawer/HeaderNavigationDrawer';
import { Loading, Error, NotFound } from 'components/status/Status';
import 'components/navigation/header-navigation/header-navigation.scss';

function HeaderScroll(props) {
  const { children } = props;
  const theme = useTheme();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    sx: trigger
      ? {
          bgcolor:
            theme.palette.mode === 'dark'
              ? theme.palette.grey[800]
              : theme.palette.primary.dark,
          transitionDuration: '500ms',
          transitionProperty: 'padding-top, padding-bottom, background-color',
          transitionTimingFunction: 'ease-in-out',
        }
      : {
          pt: 1.5,
          pb: 1.5,
        },
    elevation: trigger ? 5 : 0,
  });
}

function ScrollTop(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 200,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor'
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 10 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

export default function PrimaryNavigation(props) {
  // const { loading, error, data } = useQuery(NAVIGATION);

  const { data, isLoading, isError } = useNavigation();

  // console.log('Navigation data: ', data);

  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <Error />;
  }
  if (data.data.attributes === null) {
    return <NotFound />;
  }

  const menuItems = data.data.attributes.block;

  // console.log('data', menuItems);

  return (
    <React.Fragment>
      <HeaderScroll {...props}>
        <AppBar position="sticky" className="header" component="nav">
          <Container className="header__container">
            <Toolbar
              disableGutters
              className="header__toolbar"
              sx={{ minHeight: 'auto' }}
            >
              <Typography
                noWrap
                component="div"
                sx={{
                  mr: 2,
                  flexGrow: 0,
                  display: 'flex',
                }}
                className="header-logo"
              >
                <Link
                  to="/"
                  component={RouterLink}
                  color="inherit"
                  underline="none"
                  variant="h1"
                  sx={{
                    fontSize: { xs: 24 },
                    textTransform: 'uppercase',
                    fontWeight: '700',
                    color: 'common.white',
                    borderStyle: 'solid',
                    borderWidth: '5px',
                    borderColor: 'common.white',
                    borderRadius: '50%',
                    p: 1,
                    '&:hover': {
                      color: 'primary.dark',
                      backgroundColor: 'common.white',
                      borderColor: 'primary.dark',
                    },
                  }}
                  className="header-logo__link"
                >
                  AT
                </Link>
              </Typography>
              {isMobile ? (
                <PrimaryNavigationDrawer props={menuItems} />
              ) : (
                <MenuList
                  sx={{
                    flexGrow: 1,
                    justifyContent: 'flex-end',
                    display: { xs: 'flex' },
                  }}
                  className="navigation"
                >
                  {menuItems?.map((menuItem, index) => {
                    if (menuItem.__component === 'navigation.link') {
                      return (
                        <MenuItem
                          key={menuItem.id + index}
                          sx={{ p: 0 }}
                          className="navigation__item"
                        >
                          <Link
                            to={menuItem.url}
                            component={RouterLink}
                            color="inherit"
                            underline="none"
                            aria-current="page"
                            sx={{
                              textTransform: 'uppercase',
                              fontWeight: 'normal',
                              p: 2,
                              fontSize: 18,
                              '&:hover': {
                                backgroundColor: lightBlue['900'],
                              },
                            }}
                            className="navigation__link"
                          >
                            {menuItem.label}
                          </Link>
                        </MenuItem>
                      );
                    } else {
                      return (
                        <PrimaryNavigationDropdown
                          key={menuItem.id}
                          dropdownProps={menuItem}
                        />
                      );
                    }
                  })}
                </MenuList>
              )}
            </Toolbar>
          </Container>
        </AppBar>
      </HeaderScroll>
      <Toolbar
        id="back-to-top-anchor"
        className="_Toolbar"
        sx={{
          minHeight: '0 !important',
        }}
      />
      <ScrollTop {...props}>
        <Fab
          size="small"
          aria-label="scroll back to top"
          sx={{
            backgroundColor: 'primary.dark',
            border: '2px solid',
            borderColor: 'common.white',
            color: 'common.white',
            '&:hover': {
              borderColor: 'primary.dark',
              color: 'primary.dark',
              backgroundColor: 'common.white',
            },
          }}
          className="button-to-top"
        >
          <KeyboardArrowUpIcon
            sx={{
              fontSize: 40,
            }}
            className="button-to-top__icon"
          />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
}
