import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Markdown from 'markdown-to-jsx';
import {
  Container,
  Typography,
  Divider,
  Grid,
  Button,
  Chip,
  Link,
} from '@mui/material';
import { POST } from 'queries/graphql';
import { Loading, Error, NotFound } from 'components/status/Status';
import BlogPostCategories from 'components/blog-post-categories/BlogPostCategories';
import Head from 'components/_base/head/Head';
import PageHeading from 'components/page-heading/PageHeading.js';
import Video from 'components/video/Video.js';
import { usePost } from 'lib/post';

const MarkdownLink = ({ children, ...props }) => (
  <Link {...props}>{children}</Link>
);

export default function Post() {
  const { slug } = useParams();
  // const { loading, error, data } = useQuery(POST, {
  //   variables: {
  //     slug: slug,
  //   },
  // });

  const { data, isLoading, isError } = usePost(slug);
  console.log('POST', data);

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <Error />;
  }

  const post = data.data.attributes;
  console.log('POST', post);

  if (post === null) {
    return <NotFound />;
  }

  return (
    <React.Fragment>
      {post.seo && <Head props={post.seo} slug={post.slug} />}
      <Container sx={{ py: 10 }}>
        <PageHeading props={post.name} />
        <Grid container maxWidth="xs" spacing={{ md: 4 }}>
          <Grid item xs={12} md={9} sx={{ mb: { xs: 4, md: 0 } }}>
            <Markdown
              options={{
                forceBlock: true,
                disableParsingRawHTML: false,
                overrides: {
                  a: {
                    component: MarkdownLink,
                    props: {
                      target: '_blank',
                    },
                  },
                },
              }}
            >
              {post.content}
            </Markdown>
            <Video props={post.video} />
          </Grid>
          <Grid item xs={12} md={3}>
            <BlogPostCategories categories={post.category_arlans} />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
