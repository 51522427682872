import useSWR from 'swr';
const qs = require('qs');
const fetcher = (url) => fetch(url).then((res) => res.json());
const query = qs.stringify(
  {
    populate: [
      'seo',
      'featuredImage',
      'category_arlans',
      // 'block.buttons',
      // 'block.image',
      // 'block.bookCards',
      // 'block.bookCards.image',
      // 'block.bookCards.button',
    ],
    publicationState: 'live',
    sort: 'publishedAt:desc' // Add this line
  },
  {
    encodeValuesOnly: true,
  }
);

const resource = `${process.env.REACT_APP_STRAPI_API}/post-arlans`;

export const usePosts = () => {
  const { data, error } = useSWR(`${resource}/?${query}`, fetcher, {
    refreshInterval: 30000,
  });

  console.log('lib - posts.js: ', data);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};
