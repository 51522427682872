// import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import FooterNavigation from 'components/navigation/footer-navigation/FooterNavigation';
import NewsLetterForm from 'components/newsletter-form/NewsletterForm';
import WebsiteCredit from 'components/website-credit/WebsiteCredit';

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: 'action.hover',
        py: 1,
      }}
    >
      <Container disableGutters className="footer-container">
        {/* <NewsLetterForm /> */}
        <FooterNavigation />
        {/* <Typography
          component="p"
          variant="body1"
          sx={{
            textAlign: 'center',
            pt: 2,
            fontSize: 14,
            color: 'text.primary',
          }}
        >
          &copy; Arlan Tazmin {new Date().getFullYear()}
        </Typography> */}
        <WebsiteCredit />
      </Container>
    </Box>
  );
}
