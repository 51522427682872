import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Container, MenuList, MenuItem, Link } from '@mui/material';
// import { NAVIGATION } from 'queries/graphql.js';
import { useNavigation } from 'lib/navigation';
import { Loading, Error, NotFound } from 'components/status/Status';
import 'components/navigation/footer-navigation/footer-navigation.scss';

export default function FooterNavigation() {
  // const { loading, error, data } = useQuery(NAVIGATION);
  const { data, isLoading, isError } = useNavigation();

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <Error />;
  }

  if (data.data.attributes.block === null) {
    return <NotFound />;
  }

  const menuItems = data.data.attributes.block;

  return (
    <Container maxWidth={false} className="footer-navigation-container">
      <MenuList
        sx={{
          flexGrow: 1,
          display: { xs: 'flex' },
          flexDirection: { md: 'row' },
          justifyContent: 'center',
        }}
        dense
      >
        {menuItems?.map((menuItem, index) => {
          if (menuItem.__component === 'navigation.link') {
            if (menuItem.isShowInFooter === true) {
              return (
                <MenuItem
                  key={menuItem.id + index}
                  sx={{
                    display: {
                      xs: 'flex',
                      alignItems: 'flex-start',
                      fontSize: '1rem',
                    },
                  }}
                  className="_MenuItem"
                >
                  <Link
                    to={menuItem.url}
                    component={RouterLink}
                    className="link"
                    sx={{
                      pt: 1,
                      pb: 1,
                    }}
                  >
                    {menuItem.label}
                  </Link>
                </MenuItem>
              );
            }
          } else {
            return (
              <MenuItem
                key={menuItem.id}
                sx={{ p: 0, minHeight: 0 }}
                className="_MenuItemDropdown"
              >
                <MenuList
                  sx={{
                    display: { xs: 'flex' },
                    flexDirection: { xs: 'column', md: 'row' },
                    flexGrow: 1,
                    pt: 0,
                    pb: 0,
                  }}
                >
                  {menuItem?.links?.map((menuItem, index) => {
                    if (menuItem.isShowInFooter === true) {
                      return (
                        <MenuItem
                          key={menuItem.id + index}
                          sx={{
                            ml: 1,
                            minHeight: 'auto',
                            lineHeight: 1,
                          }}
                          className="_MenuItem"
                        >
                          <Link
                            to={menuItem.url}
                            component={RouterLink}
                            sx={{ pt: 1, pb: 1 }}
                          >
                            {menuItem.label}
                          </Link>
                        </MenuItem>
                      );
                    }
                  })}
                </MenuList>
              </MenuItem>
            );
          }
        })}
      </MenuList>
    </Container>
  );
}
