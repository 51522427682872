import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Image from 'mui-image';
import Markdown from 'markdown-to-jsx';
import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  Stack,
  useMediaQuery,
  Paper,
  Link,
  List,
  ListItem,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { buildUrl } from 'cloudinary-build-url';
import 'components/aside-with-image/aside-with-image.scss';

export default function AsideWithImage(props) {
  console.log('___AsideWithImage.js: props', props);

  const { props: data } = props;
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const theme = useTheme();
  const MarkdownLink = ({ children, ...props }) => (
    <Link {...props}>{children}</Link>
  );

  const MarkdownTypography = ({ children, ...props }) => (
    <Typography {...props}>{children}</Typography>
  );

  const MarkdownList = ({ children, ...props }) => (
    <List {...props}>{children}</List>
  );
  const MarkdownListItem = ({ children, ...props }) => (
    <ListItem {...props}>{children}</ListItem>
  );

  const StyledMarkdown = styled(Markdown)(({ theme }) => ({}));

  //data.image.data.attributes.url
  //props.props.image.data.attributes.url

  console.log('test 1111: ', data.image.data.attributes.url);

  let src = '';
  if (data.image.data.attributes) {
    src = buildUrl(data.image.data.attributes.provider_metadata.public_id, {
      cloud: {
        cloudName: process.env.REACT_APP_CLOUDINARY_NAME,
      },
      transformations: {
        gravity: 'face',
        dpr: 'auto',
        // width: 120,
        aspectRatio: '1',
        crop: 'thumb',
        radius: 'max',
        effect: {
          name: 'grayscale',
        },
      },
    });
  }

  return (
    <Container
      maxWidth="none"
      className={`aside-with-image ${
        data.isContentReversed ? 'aside-with-image--centered' : null
      }`}
    >
      {/* <Paper> */}
      <Grid
        container
        direction={data.isContentReversed ? 'row-reverse' : 'row'}
        maxWidth="lg"
        sx={{ mx: 'auto', py: 6 }}
      >
        <Grid item xs={12} md={7} sx={{ pr: { md: 6 } }}>
          <Box
            sx={{
              px: { md: data.isContentReversed ? 8 : null },
            }}
            className={`aside-with-image__content ${
              data.isContentReversed
                ? 'aside-with-image__content--centered'
                : null
            }`}
          >
            <Typography
              component="h6"
              variant="h4"
              align={data.isContentReversed ? 'center' : 'inherit'}
              sx={{
                textTransform: 'uppercase',
                textDecoration: 'underline',
                fontWeight: 500,
                mb: 3,
              }}
            >
              {data.subHeading}
            </Typography>
            <Typography
              component="h3"
              variant="h3"
              align={data.isContentReversed ? 'center' : 'inherit'}
              sx={{
                textTransform: 'uppercase',
                fontWeight: 500,
                mb: 6,
              }}
            >
              {data.heading}
            </Typography>
            <Typography
              component="div"
              align={data.isContentReversed ? 'center' : 'inherit'}
              sx={{
                mb: 6,
                // fontFamily: data.isContentReversed
                //   ? 'Poppins, Arial, sans-serif'
                //   : "Oswald, Roboto, 'Helvetica Neue', Arial, sans-serif",
                // fontSize: data.isContentReversed ? 'inherit' : 20,
                // fontWeight: data.isContentReversed ? 'inherit' : 300,
                // textTransform: data.isContentReversed ? 'inherit' : 'uppercase',
              }}
            >
              <StyledMarkdown
                options={{
                  forceBlock: true,
                  overrides: {
                    a: {
                      component: MarkdownLink,
                      props: {
                        target: '_blank',
                      },
                    },
                    p: {
                      component: MarkdownTypography,
                      props: {
                        variant: 'body1',
                        sx: {
                          mb: { xs: 2, md: 3 },
                        },
                      },
                    },
                    h2: {
                      component: MarkdownTypography,
                      props: {
                        component: 'h2',
                        variant: 'h3',
                        sx: {
                          fontWeight: 700,
                          my: 4,
                        },
                      },
                    },
                    h3: {
                      component: MarkdownTypography,
                      props: {
                        component: 'h3',
                        variant: 'h6',
                        sx: {
                          fontWeight: 600,
                          letterSpacing: 4,
                          mb: 1,
                        },
                      },
                    },
                    ul: {
                      component: MarkdownList,
                      props: {
                        sx: {
                          listStyleType: 'disc',
                          pl: 2,
                        },
                      },
                    },
                    li: {
                      component: MarkdownListItem,
                      props: {
                        sx: {
                          display: 'list-item',
                        },
                      },
                    },
                  },
                }}
              >
                {data.description}
              </StyledMarkdown>
            </Typography>
            <Stack
              direction={isMobile ? 'column' : 'row'}
              justifyContent={data.isContentReversed ? 'center' : 'flex-start'}
              spacing={4}
              sx={{ mb: { xs: 5, md: 0, width: isMobile ? '100%' : 'auto' } }}
            >
              {data.buttons.map((button) => {
                const buttonSx = {
                  px: 6,
                  textAlign: 'center',
                };
                const buttonProps = {
                  key: button.id,
                  variant: button.outlined ? 'outlined' : 'contained',
                  className: 'book-grid__button',
                };
                return button.isExternal ? (
                  <Button
                    href={button.link}
                    rel="noreferrer noopener"
                    target="_blank"
                    {...buttonProps}
                    sx={buttonSx}
                  >
                    {button.text}
                  </Button>
                ) : (
                  <Button
                    to={button.link}
                    component={RouterLink}
                    {...buttonProps}
                    sx={buttonSx}
                  >
                    {button.text}
                  </Button>
                );
              })}
            </Stack>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
          className="aside-with-image__image"
        >
          {data.image.data && (
            <Image
              key={data.image.data.id}
              // src={
              //   data.image.data.attributes.url
              // }
              src={src}
              alt={data.image.data.attributes.alternativeText}
              title={data.image.data.attributes.caption}
              showLoading
              duration={500}
              fit="contain"
              loading="lazy"
              sx={{
                borderRadius: '10px 0 10px 0',
              }}
              wrapperStyle={{ height: 'auto' }}
              className="__Image"
            />
          )}
        </Grid>
      </Grid>
      {/* </Paper> */}
    </Container>
  );
}
