import useSWR from 'swr';
const qs = require('qs');
const fetcher = (url) => fetch(url).then((res) => res.json());
const query = qs.stringify(
  {
    populate: [
      'seo',
      'block',
      'block.buttons',
      'block.image',
      // 'block.bookCards',
      // 'block.bookCards.image',
      // 'block.bookCards.button',
    ],
    publicationState: 'live',
  },
  {
    encodeValuesOnly: true,
  }
);

const resource = `${process.env.REACT_APP_STRAPI_API}/slugify/slugs/page-arlan`;

export const usePage = (slug) => {
  const { data, error } = useSWR(`${resource}/${slug}?${query}`, fetcher, {
    refreshInterval: 30000,
  });

  // console.log('lib - page.js: ', data);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};
