import React from 'react';
import { CATEGORY } from 'queries/graphql';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Loading, Error, NotFound } from 'components/status/Status';
import { Typography, Container, Grid, Link } from '@mui/material';
import { useCategory } from 'lib/category';
import Markdown from 'markdown-to-jsx';

export default function Category() {
  const { slug } = useParams();
  const { data, isLoading, isError } = useCategory(slug);
  // const { loading, error, data } = useQuery(CATEGORY, {
  //   variables: {
  //     slug: slug,
  //   },
  // });

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <Error />;
  }

  const category = data.data.attributes;
  console.log('category', category);

  if (category === null) {
    return <NotFound />;
  }

  return (
    <React.Fragment>
      <Container
        className="introduction-container"
        sx={{
          py: { xs: 2, md: 4 },
        }}
      >
        <Typography component="h1" variant="h3" gutterBottom>
          {category.name}
        </Typography>
        <Grid
          container
          maxWidth="lg"
          sx={{
            // mx: 'auto',
            flexDirection: 'column',
            textAlign: data.alignContent,
          }}
          className="introduction-grid"
        >
          {category.post_arlans.data.map((post) => (
            <Grid item key={post.id} sx={{ mb: 4 }}>
              <Typography component="h3" variant="h5">
                {post.attributes.name}
              </Typography>
              <Typography component="p" variant="body1">
                {post?.attributes?.excerpt?.substring(0, 200)}...
              </Typography>
              {/* <Markdown
                options={{
                  forceBlock: true,
                  disableParsingRawHTML: false,
                }}
              >
                {post.attributes.content.substring(0, 200)}...
              </Markdown> */}
              <Link component={RouterLink} to={`/blog/${post.attributes.slug}`}>
                Read More
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
