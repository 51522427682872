import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  List,
  ListItem,
  ListSubheader,
  Link,
  Card,
  CardMedia,
  Button,
  Typography,
  Divider,
  Chip,
} from '@mui/material';

export default function BlogPostCategories(props) {
  const video = props.props;
  console.log('VIDEO', video);

  return (
    <>
      <Divider textAlign="left" sx={{ my: 5 }}>
        <Chip label="VIDEO" />
      </Divider>
      <Card
        sx={{
          overflow: 'hidden',
          pb: '56.25%',
          mb: 5,
          position: 'relative',
          height: 0,
        }}
      >
        <CardMedia
          component="iframe"
          src={`https://www.youtube.com/embed/${video.videoId}?rel=0`}
          sx={{
            position: 'absolute',
            left: '0',
            top: '0',
            height: '100%',
            width: '100%',
          }}
        />
      </Card>
    </>
  );
}
