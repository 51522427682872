import React from 'react';
import { Container, Grid, Typography, Link, useTheme } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import 'components/introduction/introduction.scss';

export default function Introduction(props) {
  // console.log('components - introduction - Introduction.js - props: ', props);
  const { props: data } = props;
  const theme = useTheme();
  const textStyle = {
    mx: 'auto',
    px: 2,
    maxWidth: { xs: 'initial', md: '60%' },
    textAlign: { xs: 'justify', md: 'center' },
    // fontSize: { md: 21 },
    '& a': {
      color: 'inherit',
    },
    '& a:hover': {
      textDecoration: 'none',
    },
  };

  const MarkdownLink = ({ children, ...props }) => (
    <Link {...props}>{children}</Link>
  );

  return (
    <Container maxWidth="none" className="introduction-container">
      <Grid
        container
        maxWidth="lg"
        sx={{
          mx: 'auto',
          pt: { xs: 0, md: 0 },
          pb: { xs: 3, md: 10 },
          // py: { xs: 3, md: 20 },
          flexDirection: 'column',
          textAlign: data.alignContent,
        }}
        className="introduction-grid"
      >
        <Typography
          component={data.isHeadingH1Tag ? 'h1' : 'h3'}
          variant="h2"
          sx={{
            textTransform: 'uppercase',
            fontWeight: 500,
            // mb: 6,
            pt: 4,
            pb: 2,
            ...textStyle,
          }}
        >
          {data.heading}
        </Typography>
        <Typography
          component="div"
          sx={{
            ...textStyle,
            backgroundColor:
              theme.palette.mode === 'dark'
                ? theme.palette.grey[900]
                : theme.palette.common.white,
          }}
        >
          <Markdown
            options={{
              forceBlock: true,
              disableParsingRawHTML: false,
              overrides: {
                a: {
                  component: MarkdownLink,
                  props: {
                    target: '_blank',
                  },
                },
              },
            }}
          >
            {data.description}
          </Markdown>
        </Typography>
      </Grid>
    </Container>
  );
}
