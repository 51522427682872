/* 
https://mui.com/customization/typography/
https://mui.com/customization/default-theme/?expand-path=$.palette
https://mui.com/customization/default-theme/?expand-path=$.typography
https://mui.com/customization/color/
https://mui.com/customization/color/#picking-colors
https://material.io/resources/color/#!/?view.left=0&view.right=0
*/

// import { useTheme } from '@emotion/react';
import { useTheme } from '@mui/material';
import { amber, deepOrange, grey, blue, common } from '@mui/material/colors';
import {
  createTheme,
  responsiveFontSizes,
  alpha,
  darken,
} from '@mui/material/styles';

const palette = {
  light: {
    primary: {
      main: '#34C0AC',
      light: '#B1DED3',
      dark: '#00765A',
    },
  },
};

// let theme = useTheme();

export const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // primary: {
          //   main: palette.light.primary.main,
          //   light: palette.light.primary.light,
          //   dark: palette.light.primary.dark,
          // },
          // divider: amber[200],
          // text: {
          //   primary: grey[900],
          //   secondary: grey[800],
          // },
        }
      : {
          // primary: deepOrange,
          // divider: deepOrange[700],
          // background: {
          //   default: deepOrange[900],
          //   paper: deepOrange[900],
          // },
          // text: {
          //   primary: '#fff',
          //   secondary: grey[500],
          // },
        }),
  },
  typography: {
    // fontFamily: [
    //   'Oswald',
    //   'Roboto',
    //   '"Helvetica Neue"',
    //   'Arial',
    //   'sans-serif',
    // ].join(','),
    // body1: {
    //   fontFamily: 'Poppins, Arial, sans-serif',
    // },
  },
});

export const getThemedComponents = (mode) => ({
  components: {
    ...(mode === 'light'
      ? {
          MuiAppBar: {
            styleOverrides: {
              colorPrimary: {
                backgroundColor: grey[900],
              },
            },
          },
          MuiLink: {
            // variant: 'h3',
          },
          MuiButton: {
            styleOverrides: {
              root: {
                // borderRadius: 0,
                // color: common.white,
                // fontFamily:
                //   "Oswald, Roboto, 'Helvetica Neue', Arial, sans-serif",
                // fontSize: 20,
                // borderWidth: 2,
                // '&:hover': {
                //   borderWidth: 2,
                // },
              },
            },
            variants: [
              {
                props: { variant: 'contained' },
                // style: {
                //   fontFamily:
                //     "Oswald, Roboto, 'Helvetica Neue', Arial, sans-serif",
                // },
              },
              {
                props: { variant: 'outlined' },
                style: {
                  // color: palette.light.primary.main,
                },
              },
              {
                props: { variant: 'primary', color: 'primary' },
                style: {
                  // border: '4px dashed blue',
                },
              },
            ],
          },
          MuiList: {
            styleOverrides: {
              root: {},
            },
          },
          MuiMenuItem: {
            styleOverrides: {
              // root: {
              //   color: common.white,
              //   alignItems: 'stretch',
              //   fontFamily:
              //     "Oswald, Roboto, 'Helvetica Neue', Arial, sans-serif",
              // },
            },
          },
          MuiAccordion: {
            styleOverrides: {
              // root: {
              //   color: common.white,
              //   fontFamily:
              //     "Oswald, Roboto, 'Helvetica Neue', Arial, sans-serif",
              // },
            },
          },
          MuiContainer: {
            styleOverrides: {
              root: {
                position: 'relative',
                // backgroundColor: alpha(theme.palette.info.light, 0.5),
              },
            },
          },
        }
      : {
          MuiContainer: {
            styleOverrides: {
              root: {
                position: 'relative',
              },
            },
          },
        }),
  },
});
